<template>
  <section class="appointment_list_wrp">
    <div class="container-fluid">
      <div class="appointment_list_header_wrp">
        <div class="row align-items-center">
          <div class="col-auto">
            <h1 class="appointment_list_heading">
              <router-link class="btn back_btn" to="/dashboard/">
                <i aria-hidden="true" class="fa fa-chevron-left"></i>
              </router-link>Appointments
            </h1>
          </div>
        </div>
      </div>
      <div class="appointment_list_body_wrp">
        <table class="table" v-if="appointmentListData">
          <thead>
            <tr>
              <!-- <th>Doctor Name</th> -->
              <th>Patient Name</th>
              <th width="11%">Date & Time</th>
              <th class="text-center">Status</th>
              <th class="text-center">Symptoms</th>
              <th class="text-center">Video Message</th>
              <th class="text-right" width="16%">Action</th>
            </tr>
            <tr class="empty_cell">
              <th colspan="5"></th>
            </tr>
          </thead>
          <tbody v-if="appointmentListResults.length > 0">
            <tr v-for="item in appointmentListResults" :key="item.id">
              <td>
                <router-link class="consult_patient_name"
                :to="{name : 'consultWorkspace', params: {consultID: item.id }}">{{item.full_name}}</router-link>
              </td>
              <td>{{ item.appointment_time | LocalTimeFormat }} <br> {{ item.appointment_time | LocalDateFormat }}</td>
              <td class="status_btn_wrp text-center">
                  <span class="payment_status_text" v-if="item.payment_pending">Payment Pending</span>
                  <span class="payment_status_text" style="color:greenyellow;" v-else>Payment Done</span>
                </td>
              <td class="text-center">{{ item.symptoms }}</td>
              <td class="text-center"><a v-if="item.video_file" :href="item.video_file" target="_blank">Recored Video</a><template v-else>-</template></td>
              <td class="text-right">
                <template v-if="item.payment">
                  <div class="appointment_status_toggle_action appointemnt_status_closed" v-if="item.is_cancelled">
                    <label class="badge badge-danger">Cancelled</label>
                  </div>
                  <div class="appointment_status_toggle_action appointemnt_status_closed" v-else-if="item.is_closed">
                    <label class="badge badge-danger">Closed</label>
                  </div>
                  <div class="appointment_status_toggle_action appointemnt_status_closed" v-else-if="item.is_approved">
                    <label class="badge badge-success">Confirmed</label>
                    <label class="badge badge-danger cursor_pointer ml-2" @click="appointmentStatusUpdatedMethod(item, 'Close')">Close</label>
                  </div>
                  <div class="appointment_status_toggle_action" v-else>
                    <label class="badge badge-primary cursor_pointer" @click="appointmentStatusUpdatedMethod(item, 'Apporve')">Confirm</label>
                    <label class="badge badge-danger cursor_pointer ml-2" @click="appointmentStatusUpdatedMethod(item, 'Cancel')">Cancel</label>
                  </div>
                </template>
                <template v-else>
                  <div class="appointment_status_toggle_action appointemnt_status_closed"
                  v-if="item.is_cancelled">
                    <label class="badge badge-danger">Cancelled</label>
                  </div>
                  <div class="appointment_status_toggle_action" v-else>
                    <!-- <label class="badge badge-warning">Payment Is Pending</label> -->
                    <label class="badge badge-danger cursor_pointer ml-2" @click="appointmentStatusUpdatedMethod(item, 'Cancel')">Cancel</label>
                  </div>
                </template>
                <div class="pt-2">
                  <router-link :to="{name : 'consultWorkspace', params: {consultID: item.id }}" class="btn btn-primary btn-interact">Interact</router-link>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7" class="text-center">You don't have any appointments</td>
            </tr>
          </tbody>
        </table>
        <div class="compontent-loader" v-else>
          <div class="spinner"></div>
        </div>
      </div>
    </div>
  <appointment-status-popup v-if="appointmentStatusPopup" :appointmentStatusForData="appointmentStatusForData"
    :appointmentStatusForFLAG="appointmentStatusForFlag"></appointment-status-popup>
  </section>
</template>
<script>
/*eslint-disable */
import appointmentStatusPopup from '@/components/private/appointments/appointment-status-change-popup/';
import { bus } from '@/main';
import {mapGetters} from 'vuex';
export default {
  name: 'appointments',
  components: {
    'appointment-status-popup' : appointmentStatusPopup,
  },
  props: [],
  data() {
    return {
      appointmentListData: null,
      appointmentListResults:[],
      appointmentStatusPopup:false,
      appointmentStatusForData:null,
      appointmentStatusForFlag:'',
    }
  },
  computed: {
    ...mapGetters([
      'getconsultPatientGetters'
    ]),
    getConsultPatientListFun(){
      this.appointmentListData = this.$store.getters.getconsultPatientGetters.data;
      return
    },
    setInitialStatesFun(){
      if(this.appointmentListData){
        this.appointmentListResults = this.appointmentListData.data
      }
    },
  },
  mounted() {
    this.getConsultPatientListFun;
    this.setInitialStatesFun;
    bus.$on('appointmentStatusPopupBus', (data) => {
      this.appointmentStatusPopup = data
    });
  },
  methods:{
    appointmentStatusUpdatedMethod(data, flag){
      this.appointmentStatusPopup = true
      this.appointmentStatusForData = data
      this.appointmentStatusForFlag = flag
    },
  },
  watch: {
    getConsultPatientListFun(){
      return this.$store.getters.getconsultPatientGetters;
    },
    setInitialStatesFun(){
      if(this.appointmentListData){
        this.appointmentListResults = this.appointmentListData.data
      }
    },
    immediate: true
  },
}
</script>

<style lang="scss" scoped>
.appointment_list_wrp {
  .appointment_list_header_wrp {
    padding-bottom: 25px;
    .appointment_list_heading {
      color: #fff;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 700;
      margin: 0;
    }

    .online_appointment_searchbar {
      position: relative;
      display: inline-block;
      vertical-align: middle;

      .fa {
        font-size: 18px;
        cursor: pointer;
        color: #c0c0c0;
        position: absolute;
        top: 8px;
        left: 10px;
      }

      .form-control {
        background: rgba(228, 233, 237, 0.1);
        box-shadow: -5px 5px 10px rgba(82, 106, 133, 0.06);
        font-size: 18px;
        color: #fff;
        font-family: 'MyriadProRegular', sans-serif;
        border: none;
        height: auto;
        width: 280px;
        line-height: 36px;
        padding: 0px 15px 0 40px;
        -webkit-box-shadow: none;
        box-shadow: none;

        &::-moz-placeholder {
          color: #dfdfdf;
        }

        &::-webkit-input-placeholder {
          color: #dfdfdf;
        }

        &:focus::-webkit-input-placeholder {
          font-size: 0px;
        }

        &:focus::-moz-placeholder {
          font-size: 0px;
        }
      }
    }
  }

  .appointment_list_body_wrp {
    .table {
      margin: 0px;
      letter-spacing: 0.5px;
      color: #ffffff;
      border-collapse: separate;
      border-spacing: 0 3px;

      thead {
        th {
          border-bottom: 0;
          font-size: 19px;
          padding: 10px 8px;
          font-family: 'MyriadProRegular', sans-serif;
          text-transform: uppercase;
          border-top: 0;
          transition: all .5s ease;
          -webkit-transition: all .5s ease;
          -moz-transition: all ease .5s;
          background: rgba(228, 233, 237, .1);
          color: #87cefa;
        }

        .empty_cell th {
          padding: 0px !important;
          background: none !important;
        }
      }

      tbody {
        td {
          color: #fff;
          vertical-align: middle;
          padding: 10px 8px;
          font-size: 20px;
          font-family: 'MyriadProRegular', sans-serif;
          transition: all .5s ease;
          -webkit-transition: all .5s ease;
          -moz-transition: all ease .5s;
          height: 70px;
          line-height: 1.3;
          border-top: 0;
          background: rgba(228, 233, 237, .1);

          &.symptoms_item span:last-child i {
            display: none;
          }

          a {
            color: #fff;
            text-decoration: none;
            &.consult_patient_name {
                display: inline-block;
                font-size: 16px;
                text-align: justify;
                background: rgba(255, 255, 255, 0.1);
                padding: 8px 15px;
                border-radius: 5px;
                color: #fff2b0 !important;
                line-height: 1.2;
                &:hover{
                  background: #87cefa !important;
                  color: #22292f !important;
                }
            }

            &:hover {
              color: #ffd400;
            }
          }
          .btn-interact{
            padding: 8px 15px;
            font-size: 16px;
            border-radius: 3px;
            background: rgba(255, 255, 255, 0.1);
            color: #ffd400;
            border: none;
            text-transform: capitalize;
            font-weight: 400;
            letter-spacing: .03em;
            line-height: 17px;
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all ease .5s;
            box-shadow: none !important;
            outline: none;
            &:hover{
              background: #87cefa;
              color: #22292f;
            }
          }
        }
      }
    }
  }

  .status_btn_wrp {
    .btn {
      padding: 8px 10px;
      line-height: 1;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .btn-danger {
    &:hover {
      background: #87cefa;
      color: #000 !important;
      border-color: #87cefa;
    }
  }

  .payment_status_text {
    font-size: 16px;
    display: block;
    color: #ffd400;
    margin-top: 4px;
  }

  .payment_inpro_btn {
    background: #ffd400 !important;
    border: none !important;
    color: #000 !important;

    &:hover {
      background: #87cefa !important;
    }
  }

  @media only screen and (max-width:767px) {
    .container-fluid {
      padding: 0px;
    }
  }
    .appointment_status_toggle_action{
        label {
         margin-bottom: 0;
        }
        .badge{
            font-size: 16px;
            font-weight: normal;
            padding: 8px 14px;
            line-height: 1;
            border-radius: 3px;
            &.cursor_pointer{cursor: pointer;
                &:hover{
                    background: lightskyblue !important;
                    color: #000000 !important;
                }
            }
        }
    }
}
</style>